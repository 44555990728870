<template>
	<div class="formbox">
		<div class="login">
			<p class="title">{{ td("登录") }}</p>
			<el-form :model="loginForm" :rules="loginFormRules" ref="loginForm">
				<el-form-item class="username" prop="username">
					<el-input type="text" v-model="loginForm.username" prefix-icon="el-icon-user"
						:placeholder="td('请输入用户名')" auto-complete="off" />
				</el-form-item>

				<el-form-item class="password" prop="password">
					<el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-s-goods"
						:placeholder="td('请输入密码')" auto-complete="off" @keyup.enter.native="handleLogin"
						show-password />
				</el-form-item>


				<!-- <el-form-item class="confirmPassword" prop="phonenumber" v-if="PhoneON">
					<el-input v-model="loginForm.phonenumber" prefix-icon="el-icon-s-goods" type="text"
						:placeholder="td('请输入手机号码')" name="password" @keyup.enter.native="handleRegister">
						<el-select v-model="loginForm.prefix" slot="prepend" placeholder="+86" @change="changeClick"
							style="width: 100px;float: left;">
							<el-option v-for="(item,index) in phonecode" :label="item.phone_code"
								v-model="item.phoneVal"></el-option>
						</el-select>
					</el-input>
				</el-form-item> -->

				<!-- <el-form-item class="code" prop="code" v-if="PhoneON">
					<el-input v-model="loginForm.code" :placeholder="td('请输入验证码')" auto-complete="off"></el-input>
					<div class="codeimg" style="padding-left: 20px;"> -->
						<!-- <el-button type="info" @click="getphoneCode">获取验证码</el-button> -->
						<!-- <el-button :disabled="disabled" @click="getphoneCode" type="primary" v-if="timeswtich">
							{{td(btntxt)}}
						</el-button>
						<el-button type="info" :disabled="disabled" @click="getphoneCode" v-if="timeswtich==false">
							{{td(btntxt)}}
						</el-button>
					</div>
				</el-form-item> -->

				<el-form-item class="code" prop="code">
					<el-input v-model="loginForm.code" :placeholder="td('请输入验证码')" auto-complete="off"
						@keyup.enter.native="handleLogin"></el-input>
					<div class="codeimg">
						<img :src="codeUrl" @click="getCode" />
					</div>
				</el-form-item>

				<el-form-item>
					<!-- <el-select style="width: 120px" v-model="loginForm.l_Name"
						@change="select_language(loginForm.l_Name)">
						<el-option style="font-color: #707070" v-for="state in StatusOptions" :key="state.langCode"
							:value="state.langCode" :label="state.langName" />
					</el-select> -->
					<el-checkbox v-model="loginForm.rememberMe" style="margin: 0px 0px 0 80px">{{ td("记住密码") }}
					</el-checkbox>

					<div style="float: right; margin-right: 80px;">
						<router-link class="register" :to="'/forget'">{{td("忘记密码")}}</router-link>
						<!-- <router-link to="Forget">{{td("忘记密码")}}</router-link> -->
					</div>
				</el-form-item>

				<el-form-item>
					<el-button class="loginBtn" @click="handleLogin" :loading="loading" size="medium" type="primary"
						style="margin-top: 20px">
						<span v-if="!loading">{{ td("登录") }}</span>
						<span v-else>{{ td("登录中") }}...</span>
					</el-button>
					<el-divider content-position="center">{{ td("或") }}</el-divider>
					<router-link :to="'/register'">
						<el-button class="registerGo">{{ td("注册") }}</el-button>
					</router-link>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import store from "../../store/index";
	import {
		getCodeImg,
		getCodetext
	} from "@/api/login";
	import {
		encrypt,
		decrypt
	} from "@/utils/jsencrypt";
	import Cookies from "js-cookie";
	import {
		GetCategoryLangByLangCode,
		GetCaptionLangByLangCode,
	} from "@/api/system/actions";
	import {
		getLangCodeOption
	} from "@/api/system/captionLang";

	export default {
		name: "loginForm",
		data() {
			//验证密码的规则
			// var checkEmail = (rule, value, cb) => {

			//   const regEmail = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_.,/;:!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^.,/;:&*`~()-+=]+$)(?![0-9\\W_!@#.,/;:$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#.,/;:$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$.,/;:%^&*`~()-+=]{8,16}$/;
			//   if (regEmail.test(value)) {

			//     return cb();
			//   }
			//   cb(new Error( this.td("密码须包含大小写字母、数字以及特殊字符，且长度在8到16位")));
			// };
			return {
				lang: '中文',
				StatusOptions: [],
				codeUrl: "",
				cookiePassword: "",
				loginForm: {
					l_Name: "",
					username: "",
					password: "",
					rememberMe: false,
					code: "",
					uuid: "",
					ati: "",
					phonenumber: "",
					prefix: "86",
				},
				errorcode: 0,
				//验证码开关
				captchaOnOff: false,
				// PhoneON: false,
				disabled: false,
				timeswtich: true,
				time: 60,
				btntxt: "获取验证码",
				loading: false,
				phonecode: [{
					"chineseName": "中国",
					"phone_code": "+86",
					"phoneVal": "86"
				}, {
					"chineseName": "泰国",
					"phone_code": "+66",
					"phoneVal": "66"
				}, {
					"chineseName": "菲律宾",
					"phone_code": "+63",
					"phoneVal": "63"
				}, {
					"chineseName": "印度尼西亚",
					"phone_code": "+62",
					"phoneVal": "62"
				}, {
					"chineseName": "马来西亚",
					"phone_code": "+60",
					"phoneVal": "60"
				}, {
					"chineseName": "文莱",
					"phone_code": "+673",
					"phoneVal": "673"
				}, {
					"chineseName": "新加坡",
					"phone_code": "+65",
					"phoneVal": "65"
				}, {
					"chineseName": "越南",
					"phone_code": "+84",
					"phoneVal": "84"
				}, {
					"chineseName": "柬埔寨",
					"phone_code": "+855",
					"phoneVal": "855"
				}, {
					"chineseName": "老挝",
					"phone_code": "+856",
					"phoneVal": "856"
				}, ],
			}
		},
		computed:{
			loginFormRules() {
				return {
					username: [{
						required: true,
						message: this.td("请输入用户名"),
						trigger: "blur"
					}, ],
					password: [{
							required: true,
							message: this.td("请输入密码"),
							trigger: "blur"
						},
						// { validator: checkEmail, trigger: "blur" }
					],
					code: [{
						required: true,
						message: this.td("请输入验证码"),
						trigger: "change",
					}, ],
				}
			}
		},
		created() {
			this.getCode();
			this.getCookie();
			this.getDefaultLanguage();
			this.loginForm.ati = Cookies.get("_ati");
			let { token } = this.$route.query;
			if(token){
				this.$store.dispatch("tokenLogin", {token}).then(() => {
					this.$router.push({
						path: "/erp"
					});
				})
			}
			// this.loginForm.prefix = '';
			// this.loginForm.prefix = "86";
		},
		methods: {
			look() {
				console.log(222);
				console.log(this.loginForm);
			},
			select_language(value) {
				Cookies.set("langId", value);
				store.dispatch(GetCategoryLangByLangCode, value);
				store.dispatch(GetCaptionLangByLangCode, value);
			},

			//获取登录验证码
			getCode() {
				getCodeImg().then((res) => {
					this.captchaOnOff =
						res.captchaOnOff === undefined ? true : res.captchaOnOff;
					if (this.captchaOnOff) {
						this.codeUrl = "data:image/gif;base64," + res.img;
						this.loginForm.uuid = res.uuid;
					}
				});
			},
			getphoneCode() {
				if (this.loginForm.phonenumber == '' || this.loginForm.phonenumber == null) {
					this.$message({
						message: this.td('请输入手机号码'),
						type: 'error'
					});
				} else {
					let reg = /^[0-9]*$/;
					if (this.loginForm.phonenumber) {
						if (reg.test(this.loginForm.phonenumber)) {
							
							if(this.loginForm.prefix ==undefined){
								this.loginForm.prefix = '86'
							}
							console.log(this.loginForm.prefix);
							getCodetext(this.loginForm.phonenumber, this.loginForm.prefix).then(res => {
							});
							this.time = 60;
							this.$message({
								message: '发送验证码成功',
								type: 'success'
							});							
							this.timer();
						} else {
							this.$message({
								message: this.td('请输入正确的手机号码格式'),
								type: 'error'
							});
						}
					}

				}


				// if(this.loginForm.phonenumber == '' || this.loginForm.phonenumber == null){
				// 		this.$message({message: '请输入手机号码',type: 'error'});
				// }else {
				//  console.log(333333);
				// 		getCodetext(this.loginForm.phonenumber,this.loginForm.prefix).then(res => {
				// 	console.log(res);
				// 	this.time = 60;
				// 	this.timer();  
				// 		});

				// }
			},
			timer() {
				const phoneTime = Cookies.get("time");
				this.timeswtich = false;
				if (phoneTime == null) {
					// Cookies.set("time",this.time);
					this.disabled = true;
					this.time--;
					Cookies.set("time", this.time);
					this.btntxt = this.time + "秒重新获取";

					setTimeout(this.timer, 1000);
				} else {
					// this.time = Cookies.get("time");
					if (phoneTime > 0) {

						this.time = Cookies.get("time");
					}
					if (this.time > 0) {
						this.disabled = true;
						this.time--;
						Cookies.set("time", this.time);
						this.btntxt = this.time + "秒重新获取";
						setTimeout(this.timer, 1000);
					} else {
						this.time = 0;
						this.timeswtich = true;
						this.btntxt = "获取验证码";
						this.disabled = false;
					}
				}


				// const phoneTime = Cookies.get("time");
				// if(phoneTime == null){
				// 	Cookies.set("time",this.time);
				// }else {
				// 	this.time = Cookies.get("time");
				// 	if(this.time > 0) {
				// 	 this.disabled = true;
				// 	 this.time--;
				// 	 Cookies.set("time",this.time);
				// 	 this.btntxt = this.time + "秒";
				// 	 setTimeout(this.timer, 1000);
				// 	} else{
				// 	 this.time = 0;
				// 	 this.btntxt = "获取验证码";
				// 	 this.disabled=false;
				// 	}
				// }

			},
			//保存密码
			getCookie() {
				const username = Cookies.get("username");
				const password = Cookies.get("password");
				const rememberMe = Cookies.get("rememberMe");
				this.loginForm = {
					username: username === undefined ? this.loginForm.username : username,
					password: password === undefined ? this.loginForm.password : decrypt(password),
					rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
				};
			},
			getDefaultLanguage() {
				//获取语言的方法
				getLangCodeOption().then((response) => {
					this.StatusOptions = response.data;
					this.loginForm.l_Name = this.StatusOptions[0].langCode;
				});
			},
			changeClick(e) {
				// console.log(e);
				// this.loginForm.ati = Cookies.get("_ati");
				this.loginForm.prefix = e
				console.log(this.loginForm.prefix);

			},
			//登录
			handleLogin() {
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						this.loading = true;
						if (this.loginForm.rememberMe) {
							Cookies.set("username", this.loginForm.username, {
								expires: 30
							});
							Cookies.set("password", encrypt(this.loginForm.password), {
								expires: 30,
							});
							Cookies.set("rememberMe", this.loginForm.rememberMe, {
								expires: 30,
							});
						} else {
							Cookies.remove("username");
							Cookies.remove("password");
							Cookies.remove("rememberMe");
						}
						// window.sessionStorage.setItem("activePath", '/Page')
						// if (this.PhoneON == false) {
						// 	this.loginForm.swtich = false;
						// } else {
						// 	this.loginForm.swtich = true;
						// }
						this.$store.dispatch("Login", this.loginForm).then(() => {
							this.$router.push({
								path: "/erp"
							})
						}),
						// .catch((res) => {
						// 	// console.log(res);
						// 	if (res === "用户不存在/密码错误") {
						// 		this.$message('请输入短信验证码登录');
						// 		this.PhoneON = true;
						// 		this.loginForm.code = "";
						// 		// this.loginForm.prefix = "86";
						// 	}
							// this.errorcode++;	
							//  if(this.errorcode == 3){

							//  }
							this.loading = false;
							if (this.captchaOnOff) {
								this.getCode()
							}
						
					}
				})
			},
		},
	};
</script>

<style scoped>
	.formbox {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 50%;
	}

	.formbox .login {
		height: 594px;
		width: 420px;
		background: #ffffff;
		padding: 48px 32px;
		box-sizing: border-box;
	}

	.formbox .login .title {
		font-size: 20px;
		line-height: 28px;
		font-weight: 600;
		text-align: center;
	}

	.el-form {
		margin-top: 20px;
	}

	.el-form .el-input {
		height: 40px;
	}

	.el-form .code .el-input {
		width: 215px;
		float: left;
	}

	.el-form .code .codeimg {
		height: 40px;
		float: left;
		border-radius: 0.5;
	}
	.el-form .code .codeimg .el-button{
		padding: 12px 8px;
	}

	.el-form .code img {
		height: 40px;
		margin-left: 15px;
		border-radius: 0.5;
	}

	.el-form .loginBtn {
		width: 100%;
		height: 44px;
		font-size: 16px;
		border-radius: 0.5;
		color: #fff;
		background: linear-gradient(100deg, red 0%, #67a5e0 80%);
	}

	.el-form .registerGo {
		width: 100%;
		height: 44px;
		font-size: 16px;
		border-radius: 0.5;
		color: #fff;
		background: linear-gradient(100deg, #67a5e0 0%, red 80%);
	}

	.register {
		color: black;
	}

	/* 110% */
	@media screen and (max-width: 1800px) {
		.formbox {
			width: 420px;

		}

		.formbox {
			transform: scale(0.85, 0.85);
			-webkit-transform: scale(0.85, 0.85);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}
	}

	/* 125% */
	@media screen and (max-width: 1620px) {
		.formbox {
			width: 420px;
		}

		.formbox {
			transform: scale(0.75, 0.75);
			-webkit-transform: scale(0.75, 0.75);
			-moz-transform: scale(0.75, 0.75);
			-o-transform: scale(0.75, 0.75);
		}
	}

	/* 150 */
	@media screen and (max-width: 1500px) {
		.formbox {
			width: 420px;

		}

		.formbox {
			transform: scale(0.65, 0.65);
			-webkit-transform: scale(0.65, 0.65);
			-moz-transform: scale(0.65, 0.65);
			-o-transform: scale(0.65, 0.65);
		}
	}

	@media screen and (max-width: 1380px) {
		.formbox {
			width: 600px;
		}

		.formbox {
			transform: scale(0.55, 0.55);
			-webkit-transform: scale(0.65, 0.6);
			-moz-transform: scale(0.55, 0.55);
			-o-transform: scale(0.55, 0.55);
		}
	}

	.el-button--info {
		padding: 12px !important;
	}
</style>
