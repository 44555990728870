<template>
	<div>
		<!-- 头部 -->
		<div class="header">
			<div class="leftbox">
				<img src="@/assets/img/6pllogo.png" />
				<a href="javascript:;">{{td("登录")}}</a>
			</div>
			<div class="rightbox">
				<!-- <a class="server" href="javascript:;">
          <i class="iconfont icon-kefu"></i>
          <span> 联系客服</span>
        </a> -->
				<router-link class="register" :to="'/register'">{{td("立即注册")}}</router-link>
				<router-link class="register2" :to="'/forget'">{{td("忘记密码")}}</router-link>

				<el-select  class="register3" v-model="loginForm.l_Name" @change="select_language(loginForm.l_Name)">
					<el-option style="font-color: #707070" v-for="state in StatusOptions" :key="state.langCode"
						:value="state.langCode" :label="state.langName" />
				</el-select>

			</div>
		</div>
		<!-- 头部end -->
		<div class="main">
			<div class="mainBox">
				<!-- <div class="leftBox">
          <div class="circle"></div>
        </div>
        <div class="rightBox">
          <div class="circle"></div>
        </div> -->
				<LoginForm />
			</div>
		</div>
		<!-- 底部 -->
		<div class="footer">
			<p>{{td("六方国际 版权所有")}}</p>
		</div>
		<!-- 底部end -->

	</div>
</template>

<script>
	import {
		GetCategoryLangByLangCode,
		GetCaptionLangByLangCode,
	} from "@/api/system/actions";
	import store from "../store/index";
	import Cookies from "js-cookie";
	import {
		getLangCodeOption
	} from "@/api/system/captionLang";
	import LoginForm from "./loginForm";
	export default {
		name: "login",
		components: {
			LoginForm
		},
		data() {
			return{
				StatusOptions: [],
				loginForm: {
					l_Name: "",
					username: "",
					password: "",
					rememberMe: false,
					code: "",
					uuid: "",
					ati: "",
					phonenumber: "",
					prefix: "86",
				},
			}
		},
		created() {
			this.getDefaultLanguage()
			this.loginForm.ati = Cookies.get("_ati");
		},
		methods:{
			  getDefaultLanguage() {
			  	//获取语言的方法
			  	getLangCodeOption().then((response) => {
					// console.log(response);
			  		this.StatusOptions = response.data;
			  		this.loginForm.l_Name = this.StatusOptions[0].langCode;
			  	});
			  },
			  select_language(value) {
			  	Cookies.set("langId", value);
			  	store.dispatch(GetCategoryLangByLangCode, value);
			  	store.dispatch(GetCaptionLangByLangCode, value);
			  },
		}
	};
</script>

<style scoped>
	.header {
		height: 70px;
		padding: 0px 50px;
		background: #fff;
	}

	.header .leftbox {
		position: relative;
		width: 340px;
		height: 60px;
		display: inline-block;
		margin-top: 5px;

	}

	.header .leftbox img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 60px;
		width: 210px;
		max-width: 210px;
	}

	.header .leftbox a:nth-child(2) {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		color: #ff8228;
		font-size: 20px;
		font-weight: 500;
	}

	.header .rightbox {
		position: relative;
		width: 394px;
		height: 60px;
		margin-top: 5px;
		display: inline;
		float: right;
	}

	.header .rightbox .server {
		position: absolute;
		top: 50%;
		
		transform: translateY(-50%);
		color: black;
		font-size: 12px;
	}

	.header .rightbox .register {
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		margin: 0 0px 0 20px;
		padding: 8px 10px;
		font-size: 14px;
		color: #fff;
		background: #e60012;
	}

	.header .rightbox .register2 {
		position: absolute;
		top: 50%;
		right: 42%;
		transform: translateY(-50%);
		margin: 0 0px 0 30px;
		padding: 8px 10px;
		font-size: 14px;
		color: #fff;
		background: #e60012;
	}
	.header .rightbox .register3 {
		position: absolute;
		width: 120px;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		margin: 0 0px 0 30px;




	}

	/* body */
	.main {
		/* height: calc( 100vh - 120px); */
		/* background-color: #55C2F1; */
		width: 100%;
		height: 100%;
	}

	.mainBox {
		width: 100%;
		height: calc(100vh - 120px);
		margin: 0 auto;
		display: flex;
		background: url(../assets/img/LOG.png) no-repeat;
		background-size: 100% 100%;
	}

	.mainBox .leftBox {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.circle {
		width: 100%;
		height: 550px;
		/* background: url(../assets/img/loginbg.png) no-repeat ; */
	}

	/* body end */

	/* 底部 */
	.footer {
		height: 50px;
		line-height: 50px;
		font-size: 14px;
	}

	.footer p {
		text-align: center;
	}

	/* 底部end */


	/* 110% */
	@media screen and (max-width: 1800px) {
		.mainBox {
			transform: scale(0.85, 0.85);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}

		.circle {
			transform: scale(0.85, 0.85);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}
	}

	/* 125% */
	@media screen and (max-width: 1620px) {
		.mainBox {
			transform: scale(0.75, 0.75);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.75, 0.75);
			-o-transform: scale(0.75, 0.75);
		}

		.circle {
			transform: scale(0.85, 0.85);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}
	}

	/* 150 */
	@media screen and (max-width: 1500px) {
		.mainBox {
			transform: scale(0.65, 0.65);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.65, 0.65);
			-o-transform: scale(0.65, 0.65);
		}

		.circle {
			transform: scale(0.85, 0.85);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}
	}

	@media screen and (max-width: 1380px) {
		.mainBox {
			transform: scale(0.55, 0.55);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.55, 0.55);
			-o-transform: scale(0.55, 0.55);
		}

		.circle {
			transform: scale(0.55, 0.55);
			-webkit-transform: scale(0.95, 0.95);
			-moz-transform: scale(0.55, 0.55);
			-o-transform: scale(0.55, 0.55);
		}
	}
</style>
